export enum UserRole {
	ADMIN = "ADMIN",
	VALIDATOR = "VALIDATOR",
	DATA_PROVIDER = "DATA_PROVIDER",
	PROJECT_DEVELOPER = "PROJECT_DEVELOPER",
	BUYER = "BUYER",
	METHODOLOGY_CURATOR = "METHODOLOGY_CURATOR",
	EXPERT_REVIEWER = "EXPERT_REVIEWER",
}

export type AccessLevelHierarchy = {
	role: UserRole;
	siblings: UserRole[];
	supercedes: UserRole[];
};
