import { AccessLevelHierarchy, UserRole } from "@/types/Role";
import { atomWithStorage } from "jotai/utils";

export type User = {
	id: number;
	email: string;
	accessToken: string;
	refreshToken: string;
	createdMillis: number;
	status: "PENDING" | "APPROVED" | "REJECTED" | "IN_REVIEW";
	roles: UserRole[];
	rolesInfo: AccessLevelHierarchy[];
};

const userAtom = atomWithStorage<User | null>("userInfo", null);

export default userAtom;
