import { useAtomValue } from "jotai";
import Image from "next/image";
import { useMemo } from "react";

import userAtom from "@/atoms/userAtom";
import { adminDefaultRoute, methodologyCuratorDefaultRoute, reviewerDefaultRoute } from "@/constants/allowedRouteRoots";
import { UserRole } from "@/types/Role";
import Link from "next/link";
import Button from "../ui/Button";

export default function NotFound() {
	const user = useAtomValue(userAtom);
	const rootRoute = useMemo(() => {
		const role = user?.roles?.[0];
		switch (role) {
			case UserRole.ADMIN:
				return adminDefaultRoute;
			case UserRole.PROJECT_DEVELOPER:
				return methodologyCuratorDefaultRoute;
			case UserRole.EXPERT_REVIEWER:
				return reviewerDefaultRoute;
			default:
				return "/";
		}
	}, [user]);

	return (
		<div className="flex h-full items-center justify-between sm:p-4 lg:p-8 xl:p-12">
			<div className="max-w-xl space-y-6">
				<Image src="/logo-blue.svg" width={221} height={320} alt="OCP Logo" className="h-20 w-20" />
				<div>
					<h1>That page has been sequestered.</h1>
					<p>
						We couldn’t find what you were looking for—perhaps that page wasn’t permanent? Here are some additional
						links you might like instead.
					</p>
				</div>
				<div className="flex space-x-3">
					<Link href={rootRoute}>
						<Button>Return Home</Button>
					</Link>
					<Link href="/all-methodologies">
						<Button>Methodologies</Button>
					</Link>
				</div>
			</div>

			<Image src="/404.png" width={750} height={750} alt="OCP Logo" className="" />
		</div>
	);
}
